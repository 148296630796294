<template>
  <!-- margin -->
  <div class="container">
    <div class="exam">
      <div class="show">
        <div class="exam__top flex flex__space-between">
          <div class="exam__time flex flex__align-center">
            <a-icon type="history" style="color:#FE4949" />
            <div class="exam__text">倒计时:</div>
            <div class="exam__time">{{time}}</div>
          </div>
          <div class="exam__btn">
            <a-button
              type="primary"
              shape="round"
              style="width:95px;margin-right:15px;"
              :loading="loading	"
              @click="clickFun"
            >提交</a-button>
            <a-button shape="round" style="width:95px" @click="backFun">返回</a-button>
          </div>
        </div>
      </div>
      <div class="exam__title text__center">{{name}}</div>
      <div class="exam__tip text__center">满分{{score}}分，{{pass}}分通过，时长{{duration}}分钟</div>
      <div class="exam__content">
        <answers
          v-for="(item, index) in data"
          :key="index"
          :data="item"
          :index="index+1"
          @answersFun="answersFun"
        />
      </div>
      <div class="exam__btn">
      </div>
      <a-modal
        title="温馨提示"
        :visible="visible"
        cancelText="取消"
        okText="确认"
        :confirm-loading="confirmLoading"
        @ok="submit"
        @cancel="handleCancel"
      >
        <p>确定要交卷吗?</p>
      </a-modal>
    </div>
  </div>
</template>

<script>
import answers from "./components/answers.vue";
import Vue from "vue";
import { getQuesition, submitTestAnswer, getTestPaper } from "@/api/learning";

export default {
  components: { answers },
  data() {
    return {
      isLoad: false, // 是否刷新页面
      data: [],
      id: "", // 考试id
      subTask: "", // 子任务id
      duration: null,
      name: "",
      score: "",
      pass: "",
      timeVisible: false,
      taskPathId: Vue.ls.get("TASK_PATH_ID"),
      answersData: [],
      timer: null,
      time: "00:00:00",
      visible: false,
      confirmLoading: false,
      loading: false
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.subTask = this.$route.query.subTask;
      this.getDetail();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLoad) {
      next();
      return false;
    }
    this.$confirm({
      title: "温馨提示",
      content: "正在考试,确定要离开?",
      cancelText: "取消",
      okText: "确认",
      onOk() {
        next();
      },
      onCancel() {
        next(false);
      }
    });
  },
  mounted() {
    window.addEventListener("load", e => this.beforeunloadFn(e));
    // window.addEventListener("scroll", this.scrollFun);
  },
  methods: {
    // 监听刷新页面
    beforeunloadFn() {
      this.clearTimer();
      this.isLoad = true;
      this.$message.warning("取消考试!");
      this.$router.push({ name: "home" });
    },
    // 获取试卷详情
    getDetail() {
      getTestPaper({ id: this.id }).then(res => {
        this.duration = res.result.reactionTime;
        this.score = res.result.totalScore;
        this.pass = res.result.passScore;
        this.name = res.result.name;
        this.getData();
      });
    },
    // 获取数据
    getData() {
      getQuesition({
        examinationPaperId: this.id
      }).then(res => {
        this.data = res.result;
        this.second = this.duration * 60;
        this.timer = setInterval(() => {
          if (this.second === 0) {
            this.clearTimer();
            this.timer = null;
            let secondsToGo = 3;
            const modal = this.$warning({
              title: "温馨提示",
              content: `您的考试已结束, 将于 ${secondsToGo} 秒后自动提交`
            });
            const interval = setInterval(() => {
              secondsToGo -= 1;
              modal.update({
                content: `您的考试已结束, 将于 ${secondsToGo} 秒后自动提交`
              });
            }, 1000);
            setTimeout(() => {
              clearInterval(interval);
              this.submit();
              modal.destroy();
            }, secondsToGo * 1000);
            return false;
          }
          this.second--;
          this.setTimes(this.second);
        }, 1000);
      });
    },
    // 提交试卷
    submit() {
      this.clearTimer();
      this.confirmLoading = true;
      var time = this.setMinute(this.time);
      const obj = {
        id: this.id,
        levelSubTaskId: this.subTask,
        taskPathId: this.taskPathId,
        duration: time,
        questionList: this.answersData
      };
      let batchCode = null;
      submitTestAnswer(obj)
        .then(res => {
          if (res.success) {
            batchCode = res.result.batchCode;
            console.log(res.result.batchCode, "res");
            console.log(batchCode, "batchCode");
            this.$message.success("提交成功!");
            this.isLoad = true;
            var model = null;
            if (res.result.passFlag) {
              model = (
                <div>
                  <p>你的考试分数为{res.result.score}分</p>
                  <p style="display: flex;">
                    <div style="margin-right:10px">
                      <a-icon
                        type="smile"
                        style="color: rgb(23, 212, 14);font-size:16px"
                      />
                    </div>
                    恭喜考试通过!
                  </p>
                </div>
              );
            } else {
              model = (
                <div>
                  <p>你的考试分数为{res.result.score}分</p>
                  <p style="display: flex;">
                    <div style="margin-right:10px">
                      <a-icon
                        type="frown"
                        style="color: #E10101;font-size:16px"
                      />
                    </div>
                    考试不通过, 请再接再厉!
                  </p>
                </div>
              );
            }
            console.log(batchCode, "batchCode在这里");
            if (this.score === res.result.score) {
              this.$success({
                title: "考试结果",
                content: model,
                onOk: () => {
                  this.$router.back();
                }
              });
            } else {
              this.$confirm({
                title: "考试结果",
                content: model,
                cancelText: "错题解析",
                onOk: () => {
                  this.$router.back();
                },
                onCancel: () => {
                  console.log("错题解析");
                  this.$router.replace({
                    name: "wrong-problem",
                    query: { examinationPaperId: this.id, batchCode: batchCode }
                  });
                  //       this.$router.push({
                  //   name: "details",
                  //   query: { id: item.id }
                  // });
                }
              });
            }
          } else {
            this.$message.error(res.message)
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.confirmLoading = false;
          }, 1000);
        });
    },
    // 每做一道题的回调
    answersFun(data) {
      console.log(data, '答案数据')
      var isPush = true;

      this.answersData.forEach(item => {
        if (item.id === data.id) {
          isPush = false;
          if (item.answer.type === "SUBJECTIVE_SCORE") {
            item.answer.score = data.answer.score;
          } else if (item.answer.type === "OPEN") {
            item.answer.text = data.answer.text;
          } else {
            item.answer.indexList = [];
            data.answer.indexList.forEach(ele => {
              item.answer.indexList.push(ele);
            });
          }
        }
      });

      if (isPush) {
        this.answersData.push(data);
      }
      var filterData = this.answersData.filter(item => item.answer.indexList.length > 0)
      this.answersData = JSON.parse(JSON.stringify(filterData))
    },
    // 处理时间
    setTimes(x) {
      var durationValue = this.$moment.duration(x, "seconds");
      var hours = Math.floor(durationValue.asHours());
      var mins = Math.floor(durationValue.asMinutes()) - hours * 60;
      var seconds = Math.floor(durationValue.asSeconds()) - Math.floor(durationValue.asMinutes()) * 60;
      // console.log("hours:" + hours + " mins:" + mins + " seconds:" + seconds);
      var h = "";
      var m = "";
      var s = "";
      hours < 10 ? (h = "0" + hours) : (h = hours);
      mins < 10 ? (m = "0" + mins) : (m = mins);
      seconds < 10 ? (s = "0" + seconds) : (s = seconds);
      this.time = "";
      this.time = h + ":" + m + ":" + s;
    },
    // 时间转分钟数
    setMinute(str) {
      var arr = str.split(":");
      var h = "";
      var m = "";
      var s = "";
      var t = 0;
      arr.forEach((item, index) => {
        if (index === 0) {
          h = parseInt(item);
        } else if (index === 1) {
          m = parseInt(item);
        } else {
          s = parseInt(item);
        }
      });
      t += h * 3600;
      t += m * 60;
      t += s;
      return t;
    },
    // 取消对话框
    handleCancel() {
      this.visible = false;
      this.loading = false
    },
    // 退出
    backFun() {
      this.$router.back();
    },
    // 清楚定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
      console.log(this.timer, '销毁了')
    },
    // 点击提交按钮
    clickFun() {
      this.loading = true
      console.log(this.answersData,  '123321')
      if (this.answersData.length === this.data.length) {
        this.visible = true;
      } else {
        let num = null
        this.data.some((item, index) => {
          if (this.answersData.length > 0) {
            let isFind = this.answersData.some(ele => item.id === ele.id)
            if (!isFind) {
              num = index + 1
              return item
            }
          } else {
              num = 1
              return item
          }
        })
        this.loading = false
        this.$message.warning(`第${num}题没填写, 请填写完再提交!`);
      }
    }
  },
  beforeDestroy() {
    this.clearTimer();
  },
  destroyed() {
    window.removeEventListener("load", e => this.beforeunloadFn(e));
    // window.removeEventListener("scroll", this.scrollFun);
  }
};
</script>

<style lang = "scss" scoped>
.exam {
  /* margin: 36px 0 50px; */
  &__top {
    padding:0 40px;
    display: flex;
    align-items: center;
    height: 100px;
  }
  &__text {
    margin: 0 12px;
    color: #666666;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  &__time {
    color: #ff3434;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
  }
  &__title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin: 50px 0 20px;
  }
  &__tip {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  &__content {
    margin-top: 50px;
    margin-bottom: 192px;
  }
  &__pagination {
    margin-top: 40px;
    text-align: center;
  }
  &__btn {
    text-align: center;
  }
}
.rbottom {
  position: fixed;
  bottom: 200px;
  right: 60px;
  border: 1px solid #f9f9f9;
  background-color: #f9f9f9;
  padding: 36px;
  box-shadow: 1px 1px 1px 1px #f1f1f1;
}
.show {
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.05);
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 0 0 15px 15px;
}
::v-deep .ant-btn-primary{
  background-color: #5DB4FF;
  border-color: #5DB4FF;
}
</style>
