<template>
  <div class="answers">
    <div class="answers__row flex flex__space-between">
      <div class="answers__num">{{index}}</div>
      <div class="answers__score" v-if="data.score">{{data.score}}分</div>
    </div>

    <div class="answers__content">
      <div class="answers__question flex">
        <div class="answers__type" v-if="data.type === 'JUDGEMENT'">判断题</div>
        <div class="answers__type" v-if="data.type === 'RADIO'">单选题</div>
        <div class="answers__type" v-if="data.type === 'CHECKBOX'">多选题</div>
        <div class="answers__type" v-if="data.type === 'OPEN'">问答题</div>
        <div class="answers__type" v-if="data.type === 'SUBJECTIVE_SCORE'">评分题</div>
        <div class="answers__text">{{data.title}}</div>
      </div>
      <div class="answers__answer" v-if="data.type === 'RADIO' || data.type === 'JUDGEMENT'">
        <a-radio-group size="large" @change="onChange">
          <div class="mb" v-for="item in data.content.optionList" :key="item.index">
            <a-radio class="label" :value="item.index">{{item.value}}</a-radio>
          </div>
        </a-radio-group>
      </div>
      <div class="answers__answer" v-if="data.type === 'CHECKBOX'">
        <a-checkbox-group @change="oncheckChange">
          <div class="mb" v-for="item in data.content.optionList" :key="item.index">
            <a-checkbox :value="item.index">{{item.value}}</a-checkbox>
          </div>
        </a-checkbox-group>
      </div>
      <div class="answers__answer" v-if="data.type === 'OPEN'">
        <a-textarea
          placeholder="请输入"
          :auto-size="{ minRows: 2, maxRows: 6 }"
          v-model="value"
          @change="inputFun"
        />
      </div>
      <div class="answers__answer" v-if="data.type === 'SUBJECTIVE_SCORE'">
        范围1分~10分
        <a-input-number :min="1" :max="10" @change="numberInput" />
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      value: ""
    };
  },
  methods: {
    // 单选
    onChange(e) {
      let value = e.target.value;
      var obj = { answer: { type: this.data.type }, id: this.data.id };
      obj.answer.indexList = [];
      obj.answer.indexList.push(value);
      this.$emit("answersFun", obj);
    },
    // 多选
    oncheckChange(value) {
      var obj = { answer: { type: this.data.type }, id: this.data.id };
      obj.answer.indexList = [];
      obj.answer.indexList = value;
      this.$emit("answersFun", obj);
    },
    // 评分
    numberInput(value) {
      var obj = { answer: { type: this.data.type }, id: this.data.id };
      obj.answer.score = value;
      this.$emit("answersFun", obj);
    },
    // 输入
    inputFun() {
      var obj = { answer: { type: this.data.type }, id: this.data.id };
      obj.answer.text = this.value;
      this.$emit("answersFun", obj);
    }
  }
};
</script>
 
<style lang = "scss" scoped>
.mb {
  margin-bottom: 10px;
}
.answers {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.05);
  border-radius: 15px;
  margin-top: 22px;
  overflow: hidden;
  &__row {
    background: #f8f8f8;
    padding: 0 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333;
    line-height: 60px;
  }
  &__score {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  &__content {
    padding: 28px 40px;
  }
  &__question {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  &__type {
    color: #ff5613;
    margin-right: 10px;
    white-space: nowrap;
  }
  &__answer {
    padding: 26px 40px 0;
  }
}
::v-deep .ant-radio-wrapper {
  white-space: pre-wrap;
}
</style>